





















































import { Component, Vue } from 'vue-property-decorator';
import { dispatchGetSeTasks, dispatchExportSeTask } from '@/store/se-tasks/actions';

@Component
export default class ExportTask extends Vue {
  public initDate = new Date().toISOString().substring(0, 10);
  public dates = [
    { id: 1, value: this.initDate, label: 'Начальная дата' },
    { id: 2, value: this.initDate, label: 'Конечная дата' },
  ];
  public menu = [ false, false ];
  public valid = false;

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {

      const data = {
        id: +this.$router.currentRoute.params.id,
        date_from: this.dates[0].value,
        date_to: this.dates[1].value,
        result: this.$router.currentRoute.params.result,
      };

      await dispatchExportSeTask(this.$store, data);
      this.$router.push('/main/se-tasks');
    }
  }
}
